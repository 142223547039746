import { render, staticRenderFns } from "./DetailCampaign.vue?vue&type=template&id=226abe4e&scoped=true&"
import script from "./DetailCampaign.vue?vue&type=script&lang=js&"
export * from "./DetailCampaign.vue?vue&type=script&lang=js&"
import style0 from "./DetailCampaign.vue?vue&type=style&index=0&id=226abe4e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226abe4e",
  null
  
)

export default component.exports