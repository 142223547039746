<template>
  <div id="container-demo">
    <div id="wrapper-demo">
      <h1 id="text">{{text}}</h1>
      <div id="demos">
        <div class="demo"><img :src="require(`@/assets/${demo.first.image}`)" alt=""> <h4>{{demo.first.text}}</h4></div>
        <div class="demo">
          <img :src="require(`@/assets/${demo.second.image}`)" alt=""> <h4>{{demo.second.text}}</h4>
        </div>
        <div class="demo">
          <img :src="require(`@/assets/${demo.third.image}`)" alt=""> <h4>{{demo.third.text}}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'demo']
}
</script>

<style scoped>
#container-demo {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  background: rgb(0,67,171);
  background: linear-gradient(149deg, rgba(0,67,171,1) 27%, rgba(1,205,232,1) 93%);
}

#wrapper-demo {
  width: 90%;
  min-height: 75vh;
  padding-top: 2rem;
  /* background-color: khaki */
}

h1#text {
  color: #fff;
  width: 100%;
  max-width: 800px;
  line-height: 1.8;
  text-align: center;
  margin: 0 auto;
  font-size: .98rem;
}

#demos {
  width: 100%;
  height: calc(100% - 3rem);
  /* min-height: 520px; */
  padding: 1rem 3rem 0 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;;
}

.demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 2rem 0;
}

.demo img {
  width: 57%;
}

.demo h4 {
  color: #fff;
}

@media (max-width: 767.98px) {
  #container-detail-case {
    margin-bottom: 3rem;
  }

  #container-demo {
    height: auto;
  }

  #wrapper-demo {
    padding-top: 0px;
    padding-top: 3rem;
  }

  #demos {
    height: auto;
    grid-template-columns: 100%;
    grid-template-rows: .5fr .5fr .5fr;
  }

  .demo img {
    width: 25%;
    min-width: 10rem;
  }
}
</style>
