<template>
  <div id="wrapper">
    <vue-element-loading :active="loading" is-full-screen>
      <img src="@/assets/gif/Motion-Logo-.gif" width="50%">
    </vue-element-loading>
    <img  src="@/assets/Path 389.png" alt="arrow-back" id="arrow-back" @click.prevent="$router.push('/case-study')">
    <div id="banner-img">
      <img @load="imgLoad" :src="require(`@/assets/${banner}`)" alt="" id="main-showcase">
      <img src="@/assets/la-ice/Ellipse 69-min.png" alt="" id="ellipses">
    </div>
    <div id="journey">
      <div id="logo-container">
        <img v-if="logo" :src="require(`@/assets/${logo}`)" alt="" id="logo">
      </div>
      <div id="journey-content">
        <h1>Campaign Type</h1>
        <div id="list-bullet">
          <div class="list">
            <h1>{{type}}</h1>
            <p>{{ type === 'CPCV' ? 'Cost per Completed View' : type === 'Gamification' ? 'CPC' : 'Cost Per Lead' }}</p>
          </div>
          <div class="list">
            <h1>Timeframe</h1>
            <p>{{ timeframe }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['banner', 'logo', 'type', 'timeframe', 'lang'],
  data () {
    return {
      loading: true
    }
  },
  watch: {
    lang () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    }
  },
  methods: {
    imgLoad () {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style scoped>
#wrapper {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 3rem);
  max-height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: 2.5fr 1.5fr;
  padding: 0 2rem;
}

#banner-img {
  position: relative;
  min-height: calc(100vh - 3rem);
  /* max-height: 100vh; */
  width: 100%;
}

#main-showcase {
  position: absolute;
  bottom: 15%;
  width: 93%;
  left: .5rem;
  z-index: 2;
}

#ellipses {
  width: 28%;
  position: absolute;
  top: 15%;
  left: 1rem;
}

#arrow-back {
  width: 1rem;
  height: auto;
  position: absolute;
  top: 3rem;
  left: 3rem;
  z-index: 3;
  transition: 0.3s;
  cursor: pointer;
}

#arrow-back:hover {
  transform: scale(1.5);
}

#journey {
  /* background: cyan; */
  padding: 2.5rem 3rem;
  display: grid;
  grid-template-rows: 74.5694px 378.25px;;
  grid-row-gap: 50px;
  margin: auto;
}

#journey-content {
  height: 378.25px;
}

#logo-container {
  position: relative;
  height: 74.5694px;
}

#logo {
  position: absolute;
  width: 50%;
  bottom: 1rem;
}

#journey-content h1 {
  font-size: 1.8rem;
}

#list-bullet {
  width: 100%;
  height: calc(100% - 1.8rem);
  padding: 1rem 0 3.5rem 0;
  position: relative;
}

#list-bullet::after {
  content: ' ';
  position: absolute;
  height: calc(50% - 3.8rem);
  top: 24%;
  border-left: 3px solid #3E5D97;
  margin: 1rem 0 3.5rem 0;
  width: 100%;
}

.list {
  width: 100%;
  height: 50%;
  position: relative;
  padding-left: 2rem;
  color: #3E5D97;
}

.list:nth-child(1) {
  padding-top: 10%;
}

.list:nth-child(2) {
  padding-top: 5%;
}

.list:nth-child(1)::after {
  position: absolute;
  content: ' ';
  width: 1rem;
  height: 1rem;
  top: 50%;
  left: -.45rem;
  background: #3E5D97;
  border-radius: 50%;
}

.list:nth-child(2)::after {
  position: absolute;
  content: ' ';
  width: 1rem;
  height: 1rem;
  bottom: 50%;
  left: -.45rem;
  background: #3E5D97;
  border-radius: 50%;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #banner-img {
     height: 580px;
     margin: auto;
     min-height: 0;
  }

  #logo-container {
    height: 74.5694px;
  }

  #journey {
    padding: 0 3rem;
    grid-row-gap: 0;
    height: 378.25px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  #wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 350px auto;
    grid-row-gap: 50px;
    height: 700px;
    min-height: 700px;
  }

  #banner-img {
    min-height: 0;
    width: 70%;
    margin: 0 auto;
  }

  #journey {
    padding: 0 3rem;
        /* background: cyan; */
    padding: 2.5rem 3rem;
    display: grid;
    /* grid-template-rows: 74.5694px 378.25px; */
    /* grid-row-gap: 50px; */
    margin: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
  }

  #logo-container {
    height: 100%;
  }

  #logo-container #logo {
    width: 60%;
    top: 30%;
    bottom: 0;
  }

  #main-showcase {
    bottom: 0;
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  #ellipses {
    display: none;
  }

  #banner-img {
    width: 90%;
  }
}

@media (max-width: 575.98px) {
  #ellipses {
    display: none;
  }

  #wrapper {
    grid-template-columns: 100%;
    grid-template-rows: 250px auto;
    grid-row-gap: 50px;
    height: 600px;
    min-height: 600px;
  }

  #banner-img {
    min-height: 0;
    width: 100% !important;
    margin: 0 auto;
  }

  #journey {
    padding: 0 3rem;
        /* background: cyan; */
    padding: 0 0;
    display: grid;
    /* grid-template-rows: 74.5694px 378.25px; */
    grid-row-gap: 50px;
    margin: auto;
    grid-template-rows: 100%;
    grid-template-columns: 1fr 1fr;
  }

  #logo-container {
    height: 100%;
  }

  #icon-container {
    margin-right: 0;
  }

  #logo-container #logo {
    width: 60%;
    max-width: 6rem;
    top: 10%;
    bottom: unset;
    left: 10%;
  }

  #main-showcase {
    bottom: 0;
    width: 100%;
    left: 0;
  }
}
</style>
