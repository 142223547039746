<template>
  <div id="container-detail-case">
    <img class="back-case" id="back-1" src="@/assets/back-case-min.png" alt="">
    <img class="back-case" id="back-2" src="@/assets/back-case-min.png" alt="">
    <div id="wrapper-detail-case">
      <h1 id="text">{{text}}</h1>
      <div id="detail-container" v-if="contentdetail === 'la-ice'">
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/la-ice/ic_visibility_24px-min.png" alt="">
          </div>
          <div id="text-detail" v-if="lang==='id'" style="margin-bottom: 1px">
            <h2>680.000 KPI</h2>
            <h2>685.125 Aktual</h2>
            <p>View yang tercapai dalam 4 minggu</p>
          </div>
          <div id="text-detail" v-else>
            <h2>680.000 KPI</h2>
            <h2>685.125 Actual</h2>
            <p>Completed View for 4 Weeks</p>
          </div>
        </div>
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/la-ice/Group 380-min.png" alt="">
          </div>
          <div id="text-detail" v-if="lang === 'id'">
            <h3>Pria & Wanita</h3>
            <h3>18 - 34 Tahun</h3>
          </div>

          <div id="text-detail" v-else>
            <h3>Male & Female</h3>
            <h3>18 - 34 Years Old</h3>
          </div>
        </div>
        <div class="detail">
          <div id="icon-container">
            <img style="width: 2.2rem;" src="@/assets/la-ice/ic_place_24px-min.png" alt="">
          </div>
          <div id="text-detail" v-if="lang === 'id'">
            <h3>Maksimal view per orang<br/>ditayangkan di lokasi tertentu</h3>
          </div>
          <div id="text-detail" v-else>
            <h3>With maximum views per person<br/>aired in selected locations</h3>
          </div>
        </div>
      </div>

      <div id="detail-container" v-if="contentdetail === 'home-credit'">
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/la-ice/ic_visibility_24px-min.png" alt="">
          </div>
          <div v-if="lang==='id'" id="text-detail">
            <h2>140.000</h2>
            <p>Impression perbulan</p>
          </div>
          <div id="text-detail" v-else>
            <h2>140.000</h2>
            <p>Monthly impressions</p>
          </div>
        </div>
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/home-credit/Group 382-min.png" alt="">
          </div>
          <div id="text-detail" v-if="lang === 'id'">
            <h2>> 58%</h2>
            <p>Engagement melalui gamification</p>
          </div>
          <div id="text-detail" v-else>
            <h2>> 58%</h2>
            <p>Engagement through gamification</p>
          </div>
        </div>
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/home-credit/Group 376-min.png" alt="">
          </div>
          <div id="text-detail" v-if="lang === 'id'">
            <h2>> 90%</h2>
            <p>Konferensi kunjungan ke website</p>
          </div>
          <div id="text-detail" v-else>
            <h2>> 90%</h2>
            <p>Conversion to Website Visitor</p>
          </div>
        </div>
      </div>

      <div id="detail-container-abbott" v-if="contentdetail === 'abbott'">
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/la-ice/ic_visibility_24px-min.png" alt="">
          </div>
          <div id="text-detail">
            <h2>946.000</h2>
            <p>Impressions</p>
          </div>
        </div>
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/home-credit/Group 382-min.png" alt="">
          </div>
          <div id="text-detail">
            <h2>285.578</h2>
            <p>Leads {{ lang === 'id' ? '(Nama, Email, Nomor Telepon)' : '(Name, Email, Phone Number)'}}</p>
          </div>
        </div>
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/abbot/Group 384-min.png" alt="">
          </div>
          <div id="text-detail">
            <h2>33%</h2>
            <p>{{lang=== 'id' ? 'Konversi ke Youtube' : 'Conversion to Youtube Views'}}</p>
          </div>
        </div>
        <div class="detail">
          <div id="icon-container">
            <img src="@/assets/home-credit/Group 376-min.png" alt="">
          </div>
          <div v-if="lang === 'id'" id="text-detail">
            <p>3 segmentasi</p>
            <p>untuk 3 produk</p>
          </div>
          <div v-else id="text-detail">
            <p>3 segmentation</p>
            <p>for 3 products</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'contentdetail', 'lang']
}
</script>

<style scoped>
#container-detail-case {
  position: relative;
  width: 100%;
  height: calc(100% - 3rem);
  min-height: 100vh;
  display: grid;
  place-items: center;
  padding: 2rem 1rem;
}

#wrapper-detail-case {
  width: 90%;
  height: 75%;
}

h1#text {
  color: #3E5D97;
  text-align: center;
  font-size: .9rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  font-weight: 500;
}

#detail-container {
  width: 100%;
  min-height: 400px;
  /* background: cornflowerblue; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  padding: 2rem 0;
}

#detail-container-abbott {
  width: 100%;
  height: calc(100% - 3rem);
  /* background: cornflowerblue; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  padding: 2rem 0;
}

.detail {
  /* background: khaki; */
  display: flex;
  justify-content: center;
  color: #0245ab;
}

#icon-container {
  /* background: red; */
  display: grid;
  place-items: center;
  margin-right: 10px;
}

#text-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

#text-detail h2 {
  font-size: 1rem;
}

#text-detail h3 {
  font-size: .9rem;
}

#text-detail p {
  margin-top: 5px;
  font-size: .9rem;
}

#icon-container img {
  width: 3.5rem
}

.back-case {
  position: absolute;
  width: 15rem;
}

#back-1 {
  top: 0;
  left: -7.5rem;
}

#back-2 {
  bottom: 0;
  right: -7.5rem;
}

@media (min-width: 1200px) {
  h1#text {
    font-size: 1.2vw;
  }

  #text-detail h2 {
    font-size: 1.2vw;
  }

  #text-detail h3 {
    font-size: .9rem;
  }

  #text-detail p {
    margin-top: 5px;
    font-size: .9rem;
  }
}

@media (max-width: 991.98px) {
  #detail-container {
    width: 100%;
    height: auto;
    /* min-height: 520px; */
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 60px;
    padding: 2rem 0;
    margin-top: 3rem;
    grid-template-rows: repeat(3, auto);
  }

  #wrapper-detail-case {
    height: auto;
    margin-top: 5rem;
    width: 95%;
  }

  .detail {
    flex-direction: column;
  }

  #text-detail {
    text-align: center;
  }

  #icon-container img{
    width: 5rem;
    margin-bottom: 1rem;
  }

  #detail-container-abbott {
    width: 100%;
    height: calc(100% - 3rem);
    /* background: cornflowerblue; */
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 60px;
    padding: 2rem 0;
    margin-top: 3rem;
    grid-template-rows: repeat(4, auto);
  }
}
</style>
