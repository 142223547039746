<template>
  <div id="bottom-container">
    <img class="back-drop" id="back-ex" src="@/assets/back-ex.png" alt="">
    <img class="back-drop" id="dot-ex" src="@/assets/dot-ex.png" alt="">
    <div id="wrapper">
      <div id="text-container">
        <p v-if="lang === 'id'">{{ex.text}}</p>
        <p v-else>{{ex.textEn}}</p>
      </div>
      <div id="img-container">
        <img :src="require(`@/assets/${ex.image}`)" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ex', 'lang']
}
</script>

<style scoped>
#bottom-container {
  width: 100%;
  height: 380px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

#wrapper {
  width: 55%;
  height: 80%;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 1fr;
}

#text-container p {
  margin-top: 10%;
  line-height: 2;
  font-size: .9rem;
  color: #0245ab;
}

#img-container {
  position: relative;
}

#img-container img {
  width: 15rem;
  position: absolute;
  bottom: 0;
  right: 10%;
}

.back-drop {
  position: absolute;
}

#back-ex {
  bottom: .5rem;
  width: 14rem;
  right: -7rem;
}

#dot-ex {
  left: 5%;
  top: 10%;
  width: 12rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #wrapper {
    width: 68%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  #arrow-back {
    left: 2rem;
  }

  #bottom-container {
    height: auto;
  }

  #wrapper {
    width: 80%;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
  }

  #text-container {
    width: 100%;
  }

  #img-container {
    height: 300px;
  }

  #img-container img {
    right: 24%;
  }

  #text-container p {
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  #arrow-back {
    left: 2rem;
  }

  #bottom-container {
    height: auto;
    min-height: 500px;
  }

  #wrapper {
    width: 80%;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
  }

  #text-container {
    width: 100%;
    margin-top: 25%;
  }

  #text-container {
    font-size: .95rem;
  }

  #img-container {
    height: 300px;
  }

  #img-container img {
    width: 12rem;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  #text-container p {
    text-align: center;
  }
}
</style>
